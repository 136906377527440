import React, { Component, useEffect, useRef } from 'react';
import { createMedia } from '@artsy/fresnel';
import PropTypes from 'prop-types';
import { InView } from 'react-intersection-observer';
import {
  Button,
  Container,
  Grid,
  Header,
  Icon,
  Menu,
  Segment,
  Sidebar,
} from 'semantic-ui-react';

import './custom-fonts.css';

import ConductorImage from './images/Conductor.png';
import OrchestraImage from './images/Orchestra.png';
import MozartImage from './images/Mozart.jpg';

import Lottie from 'lottie-web';
import Bluetooth from './lotties/BluetoothBaton.json';
import Score from './lotties/Score.json';
import Topology from './lotties/Topology.json';
import VennDiagram from './lotties/VennDiagram.json';

const BluetoothAnimation = () => {
  const lottieContainer = useRef(null);

  useEffect(() => {
    const anim = Lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: Bluetooth,
    });

    return () => {
      anim.destroy();
    };
  }, []);

  return <div ref={lottieContainer} />;
};

const ScoreAnimation = () => {
  const lottieContainer = useRef(null);

  useEffect(() => {
    const anim = Lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: Score,
    });

    return () => {
      anim.destroy();
    };
  }, []);

  return <div ref={lottieContainer} />;
};

const TopologyAnimation = () => {
  const lottieContainer = useRef(null);

  useEffect(() => {
    const anim = Lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: Topology,
    });

    return () => {
      anim.destroy();
    };
  }, []);

  return <div ref={lottieContainer} />;
};

const VennDiagramAnimation = () => {
  const lottieContainer = useRef(null);

  useEffect(() => {
    const anim = Lottie.loadAnimation({
      container: lottieContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: VennDiagram,
    });

    return () => {
      anim.destroy();
    };
  }, []);

  return <div ref={lottieContainer} />;
};


const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
})
  
const HomepageHeading = ({ mobile }) => (
  <Container text>
    <Header
      as='h1'
      content='polymaestro'
      inverted
      style={{
        fontFamily: 'Darla, Courier',
        fontSize: mobile ? '3.15em' : '5em',
        fontWeight: 'thin',
        marginBottom: 0,
        marginTop: mobile ? '1.5em' : '3em',
        color: 'lavender'
      }}
    />
    <Header
      as='h2'
      content='Orchestration'
      inverted
      style={{
        fontFamily: 'FFDaxProRegular, Courier',
        fontSize: mobile ? '1.1em' : '1.3em',
        fontWeight: 'normal',
        marginTop: mobile ? '0.5em' : '1.1em',
      }}
    />
  </Container>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

class DesktopContainer extends Component {
  state = {}

  toggleFixedMenu = (inView) => this.setState({ fixed: !inView })

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Media greaterThan='mobile'>
        <InView onChange={this.toggleFixedMenu}>
          <Segment
            inverted
            textAlign='center'
            style={{ minHeight: 700, padding: '1em 0em' }}
            vertical
          >
            <Menu
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                <Menu.Item as='a' active>
                  Home
                </Menu.Item>
                <Menu.Item as='a'>
                  Team
                </Menu.Item>
                <Menu.Item as='a'>
                  Contact
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </InView>

        {children}
      </Media>
    )
  }
}

DesktopContainer.propTypes = {
  children: PropTypes.node,
}

class MobileContainer extends Component {
  state = {}

  handleSidebarHide = () => this.setState({ sidebarOpened: false })

  handleToggle = () => this.setState({ sidebarOpened: true })

  render() {
    const { children } = this.props
    const { sidebarOpened } = this.state

    return (
      <Media as={Sidebar.Pushable} at='mobile'>
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation='overlay'
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
          >
            <Menu.Item as='a' active>
              Home
            </Menu.Item>
            <Menu.Item as='a'>Team</Menu.Item>
            <Menu.Item as='a'>Contact</Menu.Item>
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              inverted
              textAlign='center'
              style={{ minHeight: 350, padding: '1em 0em' }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size='large'>
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name='sidebar' />
                  </Menu.Item>
                </Menu>
              </Container>
              <HomepageHeading mobile />
            </Segment>

            {children}
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Media>
    )
  }
}

MobileContainer.propTypes = {
  children: PropTypes.node,
}

const ResponsiveContainer = ({ children }) => (
  <MediaContextProvider>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </MediaContextProvider>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

const App = () => (
  <ResponsiveContainer>
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign='center'>
        <Grid.Row>
          <Grid.Column width={9}>
            <p style={{ fontSize: '2em', fontFamily: 'FFDaxProRegular, Courier' }}>
              Polymaestro introduces multi-conductor orchestras
            </p>
            <img src={ConductorImage} alt="Conductor" />
            <Header as='h3' style={{ fontSize: '2em', padding:10, fontFamily: 'FFDaxProRegular, Courier' }}>
              Musical Chess
            </Header>
            <p style={{ fontSize: '1.33em', padding:5, fontFamily: 'FFDaxProRegular, Courier' }}>
              Two or more conductors negotiate <i>in real-time</i> interpretative differences in intonation, momentum, pace, rhythm and tempo during a live performance
            </p>
            <img src={OrchestraImage} alt="Orchestra" />
            <p style={{ fontSize: '1.33em', padding:10, fontFamily: 'FFDaxProRegular, Courier' }}>
              from spatially distant locations around the orchestra.
            </p>
            <TopologyAnimation />
            <p style={{ fontSize: '1.33em', padding:5, fontFamily: 'FFDaxProRegular, Courier' }}>
              Polymaestro features a <b>new form of composition</b> that emphasizes flexibility and modularity
            </p>
            <img src={MozartImage} alt="Mozart" />
            <p style={{ fontSize: '1.33em', padding:10, fontFamily: 'FFDaxProRegular, Courier' }}>
              and pairs it with technology
            </p>
            <BluetoothAnimation />
            <p style={{ fontSize: '1.33em', padding:10, fontFamily: 'FFDaxProRegular, Courier' }}>
              to create dynamically-changing musical scores.
            </p>
            <ScoreAnimation />
            <Header as='h3' style={{ fontSize: '2em', padding:10, fontFamily: 'FFDaxProRegular, Courier' }}>
              Why Polymaestro?
            </Header>
            <p style={{ fontSize: '1.33em', padding:5, fontFamily: 'FFDaxProRegular, Courier' }}>
              The concept of <i>many conductors</i> adds an innovative wrinkle to symphonic orchestration.
            </p>
            <p style={{ fontSize: '1.33em', padding:5, fontFamily: 'FFDaxProRegular, Courier' }}>
              It's a <b>chess game among conductors</b> where the goal is harmony and synchrony, not checkmate. With Polymaestro, phrases are shifted around in a score just as chess pieces are strategically moved on a chess board. 
            </p>
            <Header as='h3' style={{ fontSize: '2em', padding:10, fontFamily: 'FFDaxProRegular, Courier' }}>
              What We Offer
            </Header>
            <p style={{ fontSize: '1.33em', padding:5, fontFamily: 'FFDaxProRegular, Courier' }}>
              Curriculum for quarter/semester-long Polymaestro courses at colleges, universities and music conservatories in the United States.
            </p>
            <p style={{ fontSize: '1.33em', padding:5, fontFamily: 'FFDaxProRegular, Courier' }}>
              Musical Chess technologies for multi-conductor orchestration.
            </p>
            <Header as='h3' style={{ fontSize: '2em', padding:10, fontFamily: 'FFDaxProRegular, Courier' }}>
              Our Mission
            </Header>
            <p style={{ fontSize: '1.33em', padding:5, fontFamily: 'FFDaxProRegular, Courier' }}>
              Introduce magnificent new forms of artistry and expression to symphonic orchestration. 
            </p>
            <Header as='h3' style={{ fontSize: '2em', padding:10, fontFamily: 'FFDaxProRegular, Courier' }}>
              About
            </Header>
            <p style={{ fontSize: '1.33em', padding:5, fontFamily: 'FFDaxProRegular, Courier' }}>
              Polymaestro is a registered 501(c)(3) non-profit CA corporation.
            </p>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
    
    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='Home' />
            </Grid.Column>
            <Grid.Column width={11}>
              <p>
                <span>&#169;</span> 2023 Polymaestro.&nbsp;&nbsp;&nbsp;&nbsp; All Rights Reserved
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </ResponsiveContainer>
)

export default App;
